export const enum WindowStateListenerType {
	Maximized,
	Focused,
	Fullscreen
}

// export enum WindowStateListenerType{
// 	Maximized = 'maximized',
// 	Focused = 'focused',
// 	Fullscreen = 'fullscreen',
// }